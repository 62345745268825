.container {
    width: 400px;
    margin: 20px auto;
}

.label {
    font-weight: bold;
}

.label span {
    color: red;
}

.searchcontainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.searchbox {
    position: relative;
    width: 100%;
}

.searchbox input {
    width: 100%;
    padding: 10px 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.searchbox .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #666;
}

.searchbutton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.searchbutton:hover {
    background-color: #0056b3;
}

.emptystate {
    text-align: center;
    margin-top: 40px;
}

.emptystate img {
    width: 150px;
    margin-bottom: -20px;
}

.emptystate h3 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.emptystate p {
    color: #666;
    font-size: 14px;
}
.listData{
    padding-top: 10px;
}
.listData div[class^="styles__List-"]{
    border: 1px solid #e3e3e3;
}
.emptyContent{
    padding-bottom: 20px;
}