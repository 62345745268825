.subsctiptionInfo {
  border-bottom: solid 1px #ddd;
  display: flex;
  margin: 0 0;
  font-size: var(--font-size-x-small);
}

.subsctiptionInfo .box {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  margin: auto;
  position: relative;
  padding: 10px;
}
.subsctiptionInfo .box:first-child {
  margin-left: 15px;
}

.box .higlight {
  color: #1a52eb;
  font: var(--legacy-typography-label);
  padding: var(--spacing-xx-small) var(--spacing-none);
}

.box .higlight.active {
  color: var(--legacy-color-lime-green-500);
}

.box .higlight.cancelled {
  color: var(--legacy-color-poppy-red-300);
}

@media screen and (max-width: 1300px) {
  .subsctiptionInfo .box {
      flex-direction:column;
      gap:2px;
  }
}