// To be imported from other file 

#_launchPadTile>div:first-child {
  background: white;
}

$spacing: 11px;
$border-radius: 20px;

@mixin tablet {
  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation : landscape) {
    @content;
  }
}

body {
  background-color: #EDEFF4 !important;
  // margin-right: $spacing !important;
  // margin-left: $spacing !important;
}

#findCase {
  padding-right: $spacing;
  padding-left: $spacing;

  .SelectBar,
  .TextBar input {
    border-radius: $border-radius !important;
  }

  &Cols {
    grid-template-columns: repeat(3, minmax(280px, auto)) !important;

    @include tablet() {
      grid-template-columns: repeat(3, minmax(280px, auto)) !important;
    }

    div {
      @include tablet() {
        // flex-grow: unset !important;
        justify-content: start !important;
      }
    }
  }
}

#caseIndustry {
  div {
    @include tablet() {
      grid-template-columns: repeat(4, minmax(0px, 1fr)) !important
    }
  }
}

#stepform-coniguration-area-id {
  padding: 0 !important;
  padding-left: 1rem !important;
}


.top-banner-section {
  display: grid;
  grid-template-columns: 1fr; // stretch to the full frame
  grid-template-rows: 350px; // 350 pixels tall
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-content: center;
  justify-content: center;

  .banner-image-div {
    grid-area: 1 / 1 / 2 / 2;
  }

  // image
  .banner-overlay-div {
    grid-area: 1 / 1 / 2 / 2;
  }

  // gradient or other overlay
  .banner-text-div {
    grid-area: 1 / 1 / 2 / 2;
  }

  // overlay objects like text, buttons, etc.
}

// Banner image (layer 1)
.banner-image {
  display: grid;
  min-width: 350px; // Do not resize to smaller than this.
  width: 100%;
  height: 100%;
  object-fit: cover; // Using this so the image can be any size and still look halfway decent.
}

// Gradient overlay (layer 2)
// gradient overlay going from black to transparent.
// note: search for a gradient overlay generator to make this easier.
.banner-overlay-div {
  display: grid;
  max-width: 100%;
  background: black;
  background: linear-gradient(60deg,
      rgba(0, 0, 0, 0.7777485994397759) 30%,
      rgba(255, 255, 255, 0) 100%); // start at black at the bottom left'ish and goes at a 60% angle. This will make the white easy to read with nearly any image.
}

// Banner html components (layer 3)
// banner text
.banner-text-div {
  display: grid;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}


// Typograhy: *** This is all the stuff you change
.banner-h1-text {
  // font can get larger, but no smaller than 10 points.
  font-size: calc(10pt + 0.15vw);
  letter-spacing: 0.05em;
  // font-weight: bolder;
  // text-transform: uppercase;
  color: white;
}

.banner-body-text {
  // font can get larger, but no smaller than 10 points.
  font-size: calc(10pt + 0.15vw);
  margin-top: 0.5em;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
  }

  &:visited {
    color: white;
  }

  &:active {
    color: white;
  }
}

.banner-btn {
  margin-top: 1em;
}

.banner-btn-item {
  font-size: calc(8pt + 0.15vw); // responsive size, but keep a minimum.
  padding-top: calc(0.5em + 0.08vw);
  padding-bottom: calc(0.5em + 0.08vw);
  padding-left: calc(0.5em + 0.08vw);
  padding-right: calc(0.5em + 0.08vw);
  color: blue;
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid white;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }
}


.body_cui {
  margin: 3rem;
}

.roundedIcon_cui {
  background-color: #F3F7FE;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  text-align: center;
  // background-image: url('https://icons-for-free.com/iconfiles/png/512/document+new+new+document+icon-1320165659637116676.png');
  place-content: center;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  margin: 1rem;
}

$primaryColor: rgb(1, 18, 53);
$secondaryColor: #0065ED;

.bg-secondary {
  background-color: $secondaryColor !important;
}

.bg-primary {
  background-color: $primaryColor !important;
}

.m {
  margin: $spacing;
}

.ml {
  margin-left: $spacing;
}

.mr {
  margin-right: $spacing;
}

.mt {
  margin-top: $spacing;
}

.mb {
  margin-bottom: 1rem !important;
}

.p {
  padding: 1rem;

  &-t {
    padding-left: 1rem;
  }

}

.pb {
  padding-bottom: $spacing;
}

.p-5 {
  padding: 5rem;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white !important;
}

.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}



#nprogress .bar {
  height: 6px;
}

[hidden=""] {
  display: none !important;
}


#right [data-flag="left"] {
  display: none !important;
}

#left [data-flag="right"] {
  display: none !important;
  ;
}

/*custome css - landing-top-nav-border-fix*/
#logout {
  //background-color: #005AFF;
  border-bottom: 0px;

  span {
    color: white;
  }
}

.MyLoader_spinner svg circle {
  stroke: rgb(0, 90, 255);
  stroke-width: 4;
}



.card {
  background-color: white;
  border-radius: 8px;
  padding: 18px;
}

.readonly {
  background: linear-gradient(to left, white 50%, #F6F6F6 50%) !important;
}

.action-column-cell {
  padding: 2px; // Adjust padding as needed
  font-size: 10px; // Adjust font size as needed
  // Add more styling properties as needed
}