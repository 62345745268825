.cartBody {
  display: flex;
  justify-content: center;
  gap: .5rem;
  margin-right: 5px;

}

.cartItems {
  margin-bottom: 10px;
}

.cartList {
  align-items: center;
  width: 65%;
  background-color: #fff;
  /* padding: 1rem; */
  border-radius: 10px;
  min-height: 600px;
}

.CardContentContainer-kLhfDy .jOStRH {
  min-height: auto;
}

.cartSummary {
  width: 35%;
  /* padding: 1rem; */
  background-color: #fff;
  border-radius: 10px;
  min-height: 600px;
}

.cartListItem {
  /* width: 600px;  */
  /* height: 13rem !important;  */
  /* min-height: 11rem;   */
  border-radius: 10px;
  display: flex;
  /* margin-right: 1rem; */
  padding: 1rem;
  min-height: auto !important;
}

.verticalDivider {
  border-left: 1px solid #D7D7D7;
  /* height: 8rem; */
}

.horizontalDivider {
  border-bottom: 1px solid #D7D7D7;
  width: 100%;
}

.styles__CardContentContainer-kLhfDy .jOStRH .productCard {
  min-height: auto !important;
}

.productCard {
  display: flex;
}

.font14 {
  font-size: 14px;
  font-weight: 400;
}

.productImage img {
  max-width: 100%;
  height: 50px;
  object-fit: contain
    /* border-radius: 8px; */
}

.productImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  /* height: 55px; */
}

.productDetails {
  margin-left: 10px;
  width: 40%;
}

.productPriceQuantity {
  margin-left: auto !important;
  width: 40%;
}


.discountSaveSection {
  display: flex;
  gap: .3rem;
  margin-top: 50px;
}

.discountChip {
  color: #000;
  padding: .4rem;
  border-radius: 15px;
  border: 1px solid #80E6FF;
  font-size: .75rem;
  background-color: #E8FBFF;
}

.marketplaceDeleteBtn {
  background-color: #E4F0FF !important;
}

.marketplaceDeleteBtnText {
  color: #005AFF;
}

/* new css for cart items*/
.cartSummarySubHeading {
  font-size: 14px;
  font-weight: bold;
  padding: .5rem;
  border-bottom: 1px solid #D7D7D7;
  color: #212121;
}

.cartSummaryAccountLabel {
  font-size: 14px;
  font-weight: bold;
  color: #757575;
  margin-bottom: .5rem;
}

.cartSummaryAccountLabel span {
  font-weight: normal;
  margin-left: .4rem;
  color: '#757575';
}

.cartSummaryOrderSection {
  padding: 2rem;
  border: 1px solid #D0E0F2;
  border-radius: 8px;
  min-height: 5rem
}

.cartSummaryTable {
  width: 100%;
  border-collapse: collapse;
}

.cartSummaryTableRow {
  /* padding: .3rem; */
  background-color: #E4F0FF;
  /* margin-bottom: .3rem */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.2rem;
}
.cartSummaryTableBody{
   display: block;
   padding: .5rem;
   border-bottom: 1px solid #E8E8E8;

}
.cartSummaryTableBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.2rem;
}

.cartSummaryTableBodyColumns{
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}


.cartSummaryTableRowHead {
 font-weight: bold;
 color: #333333; 
 font-size: 13px;
  /* margin-bottom: .5rem; */
  padding: 0.5rem;
  white-space: 'normal';
}

.cartSummarySubTotal{
  padding: 2px;
  background-color: #F3F3F3;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.cartGrandTotalContainer{
  display: flex;
  justify-content: space-between;
  padding: .3rem;
  margin-top: 2px;
}

.cartGrandShippingContainer{
  display: flex;
  justify-content: space-between;
  padding: .3rem;
  margin-top: 2px;
  background-color: #F3F3F3;
}


.cartDiscountChipContainer{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}