.imgFluid{
    max-width: 100%;
    height: auto;
}

.tableResponsive{
    display: block;
    width: 100%;
    overflow-x: auto;
}

.downloadBtn{
    background-color: #005AFF;
    color: #FFFFFF;
}

.dFlex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBlock{
    display: flex;
    width: 30%;
}
.searchBlock div[class^="styles__FilterField-"] {
    width: 70%;
  }
