.QuantityModifier {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.QuantityModifier select {
  border: solid 1px #7f7c7c;
  background-color: #e0e0e0;
  width: 100%;
  height: 25px;
  border-radius: 10px;
  text-align: center;
}
