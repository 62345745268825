.adminFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0 1.5rem 0
}

.adminFiltersSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .5rem;
}

.userLabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px, ;
  color: #757575;
  margin-bottom: 5px;
}

.userInfoLabel {
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 21px !important;
  color: #757575;
  margin-bottom: 10px;
}

.width20 {
  width: 20% !important;
}

.dFlex5 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.mt25 {
  margin-top: 25px;
}

.dFlex {
  display: flex;
  gap: .5rem;
}

.userRolesActionButtons {
  display: flex;
  gap: .5rem;
  padding: 1rem 0;
}

.disabledRow {
  background-color: #f5f5f5 !important;
  /* Light gray background */
  color: #aaa;
  /* Gray text for disabled state */
  pointer-events: none;
  /* Disable all interaction */
}

.disabledRow .toggleButton {
  pointer-events: auto;
  /* Enable interaction with the action buttons */
  opacity: 1;
  /* Ensure action buttons are fully visible */
}

.marginBottom {
  margin-bottom: 1rem;
}

.marginTop {
  margin-top: 1rem;
}

.dFlexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.columnHeader {
  background-color: #0303030D;
}

.borderBottomLine {
  border-bottom: 1px solid #0000001F;
  margin: 1rem 0;
  padding: 0 !important;
}

.userFilterContainer div[class^="styles__FieldWrapper-"] input[class^="styles__Field-"] {
  padding-left: 40px !important;
}

.userRolesFilterContainer {
  display: flex;
  gap: .5rem;
  width: 40%;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(534px, .3fr));
  grid-template-rows: auto;
  gap: 20px;
}


.gridContainerMachine {
  display: grid;
  grid-template-columns: 1fr;
  /* Single column */
  gap: 10px;
  /* Space between grid items */
}

.gridItem {
  /* background-color: #d0e8f2;
    border: 1px solid #8ca0b3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333; */
}

.gridContainerNew {
  display: grid;
  grid-template-columns: repeat(3, 0.6fr);
  /* 3 columns */
  grid-template-rows: repeat(3, 1fr);
  /* 6 rows */
  gap: 10px;
  /* Space between grid items */
}

.gridItemEmpty {
  background-color: #d0e8f2;
  border: 1px solid #8ca0b3;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.selectBoxStyle div[class^="styles__SelectItemWrapper-"] {
  max-width: 99.5% !important
}

.gridContainerNewIP {
  display: grid;
  grid-template-columns: repeat(4, 0.6fr);
  /* 3 columns */
  grid-template-rows: repeat(3, 1fr);
  /* 6 rows */
  gap: 10px;
  /* Space between grid items */
  padding: 20px;
}

.testBharathww div[class^="styles__SList"]{
  overflow: hidden;
}

.gridItemNSA {
display: flex;
}

.marginBottomWidth {
  margin-bottom: 1rem;
  width: 100%;
}
.userOverleay{
  position: fixed !important;
  right: 1.5rem !important;
}
.flexContainer{
  display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    gap: 10px; /* Space between items */
}
.userDataList{
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
/* .userDataList div[class="ag-body-horizontal-scroll-viewport"]{
  overflow-x: hidden !important;
}
.userDataList div[class="ag-body-vertical-scroll-viewport"]{
  overflow-y: hidden !important;
} */
.searchValueText div[class^="styles__List"]{
  box-shadow: none !important
}


.gridcontainersearch {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Three sections */
  align-items: center;
  gap: 15px;
  background: white;
  padding: 20px 0 0 0;
}
 
/* Search input and button container */
.searchcontainer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 76%;
}
 
/* Input field */
.searchinput {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 
/* Search button */
.searchbutton {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
/* Divider */
.divider {
  height: 30px;
  width: 2px;
  background: #ccc;
}
 
/* Select dropdown */
.selectbox {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
}
 
.searchcontainer div[class^="styles__FilterField"]{
  width: 100% !important;
}
.userOverLayPanelBlock div[class^="userOverleay-"]{
  /* max-height: 0 !important;
  min-height:  0 !important; */
  height:  400px !important;

} 

.userOverleay div[class^="styles__Elevation-"]{
  max-height: 0 !important;
  min-height:  70vh !important;
  height:  100vh !important;
  overflow-y: auto !important;

} 