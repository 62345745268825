.eeWrapper {
  display: flex;
  position: relative;
  color: var(--g-color-global-secondary-content);
  font: var(--legacy-typography-body);
  height: 25px;
  margin: 5px 0;
}
.eeWrapper .eeBox {
  background-color: var(--ff-color-white);
  box-shadow: 0 var(--shadow-spread-2) var(--shadow-spread-8) 0
    rgba(var(--ff-color-black-rgb), var(--opacity-xx-light));
  backdrop-filter: blur(5px);
  border-radius: var(--radius-x-small);
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  padding: 5px;
}
.eeWrapper .eeBox:hover{
  box-shadow: 0px 0px 2px 0px #000000ab;
}
.eeBox .eeLabel {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: ltr;
  font: var(--ff-typography-body);
  display: inline-block;
  line-height: 16px;
}
.eeBox .eeConfigIco {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-top: -3px;
}
/* .eeBox .eeConfigIco svg {
  margin-top: 2px;
  margin-left: 1px;
  width: 18px;
  height: 18px;
} */

.downloadIcon{
  margin-top: -2px;
}

.eeBox button[class^="styles__IconButton"]:focus {
  outline: none;
  box-shadow: unset;
}
.eePopMenuHeader {
  border-bottom: solid 1px #cccccc94;
  display: flex;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0.5rem;
}
.eeFldConfigList ul[data-selector="ee-list-fld--ul"] {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  padding: 10px;
  gap: 0 5px;
  justify-content: space-between;
}
.eeFldConfigList ul[data-selector="ee-list-fld--ul"] li {
  width: 30%;
  margin:3px 0;
}