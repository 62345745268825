.sub-filter {
    /* border-bottom: solid 1px #ddd; */
    display: flex;
    margin: 0 0;
    padding: 1em;
    padding: 1em;
}

.selection {
    display: flex;
    flex-direction: column;
    /* width: 30%; */
    margin-right: 1%;
    position: relative;
}

/* .mainFilterSelection{

} */

.selection select {
    border: solid 1px #999494;
    height: 2rem;
    border-radius: 0.125rem;
}

.optionLoader {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 50%;
}

.optionLoader.hide {
    display: none;
}


.filterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 1rem;
    background-color: #fff;
    border-radius: 10px;
    /* flex-wrap: wrap; */
}

.mainFilterContainer {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    padding: 10px;
    /* gap: 1rem; */
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    /* flex-wrap: wrap; */
}

/* .searchBox {
    width: 25%;
    margin-bottom: 3px;
    margin-right: .5%;
} */

.verticalDivider{
    border-left: 1px solid #D7D7D7;
    /* height: 8rem; */
    
}

.filterSelection{
    display: flex;
    flex-direction: column;
    width: 22%;
    margin-left: 1%;
    /* gap: 1rem; */
}

.searchBox{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 1%;
}

.filterSelection select {
    border: solid 1px #999494;
    height: 2rem;
    border-radius: 0.125rem;
}

.filterTabs{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.labelText{
    font: var(--ff-typography-body);
    margin-right: 15px;
    margin-top: 5px;
    color: var(--g-color-global-secondary-text);
}

.sortbyBlock{
    display: flex;
}

.filterSelectionSortby{
    display: flex;
    flex-direction: column;
    width: 58%;
    margin-left: 1%;
    /* gap: 1rem; */
}

.marketPlaceFilterContainer {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 1rem;
    background-color: #fff;
    border-radius: 10px;
    /* flex-wrap: wrap; */
}
.userLabel {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px, ;
    color: #757575;
    margin-bottom: 5px;
  }
  