#_launchPadTile > div:first-child {
  background: white;
}

body {
  background-color: #EDEFF4 !important;
}

#findCase {
  padding-right: 11px;
  padding-left: 11px;
}
#findCase .SelectBar,
#findCase .TextBar input {
  border-radius: 20px !important;
}
#findCaseCols {
  grid-template-columns: repeat(3, minmax(280px, auto)) !important;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation: landscape) {
  #findCaseCols {
    grid-template-columns: repeat(3, minmax(280px, auto)) !important;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation: landscape) {
  #findCaseCols div {
    justify-content: start !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation: landscape) {
  #caseIndustry div {
    grid-template-columns: repeat(4, minmax(0px, 1fr)) !important;
  }
}

#stepform-coniguration-area-id {
  padding: 0 !important;
  padding-left: 1rem !important;
}

.top-banner-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 350px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-content: center;
  justify-content: center;
}
.top-banner-section .banner-image-div {
  grid-area: 1/1/2/2;
}
.top-banner-section .banner-overlay-div {
  grid-area: 1/1/2/2;
}
.top-banner-section .banner-text-div {
  grid-area: 1/1/2/2;
}

.banner-image {
  display: grid;
  min-width: 350px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.banner-overlay-div {
  display: grid;
  max-width: 100%;
  background: black;
  background: linear-gradient(60deg, rgba(0, 0, 0, 0.7777485994) 30%, rgba(255, 255, 255, 0) 100%);
}

.banner-text-div {
  display: grid;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.banner-h1-text {
  font-size: calc(10pt + 0.15vw);
  letter-spacing: 0.05em;
  color: white;
}

.banner-body-text {
  font-size: calc(10pt + 0.15vw);
  margin-top: 0.5em;
  color: white;
  text-decoration: none;
}
.banner-body-text:hover {
  color: white;
}
.banner-body-text:visited {
  color: white;
}
.banner-body-text:active {
  color: white;
}

.banner-btn {
  margin-top: 1em;
}

.banner-btn-item {
  font-size: calc(8pt + 0.15vw);
  padding-top: calc(0.5em + 0.08vw);
  padding-bottom: calc(0.5em + 0.08vw);
  padding-left: calc(0.5em + 0.08vw);
  padding-right: calc(0.5em + 0.08vw);
  color: blue;
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid white;
}
.banner-btn-item:link {
  text-decoration: none;
}
.banner-btn-item:visited {
  text-decoration: none;
}

.body_cui {
  margin: 3rem;
}

.roundedIcon_cui {
  background-color: #F3F7FE;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  text-align: center;
  place-content: center;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  margin: 1rem;
}

.bg-secondary {
  background-color: #0065ED !important;
}

.bg-primary {
  background-color: rgb(1, 18, 53) !important;
}

.m {
  margin: 11px;
}

.ml {
  margin-left: 11px;
}

.mr {
  margin-right: 11px;
}

.mt {
  margin-top: 11px;
}

.mb {
  margin-bottom: 1rem !important;
}

.p {
  padding: 1rem;
}
.p-t {
  padding-left: 1rem;
}

.pb {
  padding-bottom: 11px;
}

.p-5 {
  padding: 5rem;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white !important;
}

.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

#nprogress .bar {
  height: 6px;
}

[hidden=""] {
  display: none !important;
}

#right [data-flag=left] {
  display: none !important;
}

#left [data-flag=right] {
  display: none !important;
}

/*custome css - landing-top-nav-border-fix*/
#logout {
  border-bottom: 0px;
}
#logout span {
  color: white;
}

.MyLoader_spinner svg circle {
  stroke: rgb(0, 90, 255);
  stroke-width: 4;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 18px;
}

.readonly {
  background: linear-gradient(to left, white 50%, #F6F6F6 50%) !important;
}

.action-column-cell {
  padding: 2px;
  font-size: 10px;
}/*# sourceMappingURL=App.css.map */