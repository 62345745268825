.dgWrapper {
  padding: 0 10px;
  margin-bottom: 0;
}
/* .dgWrapper div.ag-body-horizontal-scroll {
  width: 100%;
  display: inline-grid;
  border: solid 1px red;
} */
.subscription_status {
  display: flex;
  position: relative;
}
.subscription_status.Active {
  color: var(--legacy-color-lime-green-500);
}
.subscription_status.Expired,
.subscription_status.Cancelled {
  color: var(--legacy-color-poppy-red-300);
}