
.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: auto;
    /* gap: 20px; */
}

.marketplaceProductCard{
    padding: 2rem;
    border-radius: 20px;
    
}

.marketplaceProductCardBody{
    height: 150; 
    padding: 0.85rem 0.85rem 0 .85rem;
    min-height: unset;
}

.marketplaceProductCardName{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.marketplaceAddtoCartBtn{
    background-color: #E4F0FF !important;
}

.marketplaceAddtoCartBtnText{
    color: #005AFF;
}

  .priceChip {
    color: #000;
    padding: 0.2rem;
    border-radius: 17px;
    border: 1px solid #000;
    margin-left: auto!important;
    font-size: 12px;
  }

 .marketplaceProductDescription{
    color: #757575;
    font-size: .14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400
 }

 .mlAuto, .mrAuto{
    margin-left: auto!important;
 }

 .marginBottom{
    margin-bottom: .5rem;
 }

 .optionLoader {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 50%;
}

.optionLoader.hide {
    display: none;
}

.productSupplierLogo{
    display: block; 
    max-width: 100%;
    height: 18px;
    object-fit: contain
}

.etraTelecomLogo{
    display: block; 
    max-width: 100%;
    height: 30px;
    object-fit: contain
}

/* new product card css */
.productCardContainer{
    border-radius: 8px,;
    display: flex;
    margin-right: 1rem;
    height: fit-content;
     margin-bottom: 1rem;
    cursor: pointer;
    min-height: auto; 
    border: 1px solid #DDDDDD
}

.productCard{
    height: 400px;
}

.productCardImageContainer{
    height: 216px;
    background-image: linear-gradient(to left, #E1EFFE40, #96AAC540)
}

.productCardImageSectionHeader{
    display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 1rem
}

.productCardDescription{
    font-size: 14px;
    color: #212121;
    font-weight: 700;
    margin: 10px 0 10px 0
}