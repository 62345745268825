
/* .catalogueBody{
    display: flex;
    gap: .5rem;
    margin-right: 5px;
    overflow: hidden;
    height: 100vh;
}

.leftFilterSection{
    align-items: center;
    width: 20%;
    background-color: #fff;
    border-radius: 10px;
    min-height: 600px;
    position: sticky; 
    top: 0; 
    height: 100vh; 
    overflow-y: auto; 
}

.leftFilterContainer{
    padding: 1rem;
}
.rightCatalogueSection{
    width: 80%;
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto; 
    max-height: 100vh; 
} */


.catalogueBody{
    display: flex;
    gap: .5rem;
    margin-right: 5px;
}

.leftFilterSection{
    align-items: center;
    width: 20%;
    background-color: #fff;
    /* padding: 1rem; */
    border-radius: 10px;
    min-height: 600px;
}

.leftFilterContainer{
    padding: 1rem;
}
.rightCatalogueSection{
    width: 80%;
    background-color: #fff;
    border-radius: 10px;
    /* padding: 1rem; */
}

