.AppBodyContent {
  display: flex;
  gap: 0 10px;
}
.AppBodyContent .contentWrapper {
  flex-basis: 75%;
}

.AppBodyContent .rightBoxWrapper {
  flex-basis: 25%;
}

.card {
  background: #fff !important;
  border-radius: 5px;
}

.selection select {
  border: solid 1px #999494;
  height: 2rem;
  border-radius: 0.125rem;
}

.subAside .asBlocks {
  border-bottom: solid 1px #ddd;
  padding: 20px 15px;
}
.subAside .asBlocks ul {
  padding: 0;
  list-style: none;
}
.subAside .asBlocks ul.DelegateListBox li {
  position: relative;
  list-style: none;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 1px 0px 2px 1px #ddd;
  margin-bottom: 5px;
  border: solid 1px #ddd;
}
.subAside .asBlocks ul {
  list-style: none;
  padding: 5px 0;
  position: relative;
  margin: 0;
}

.subAside .asBlocks.renewal {
  display: grid;
  gap: 10px;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.subAside .asBlocks.expiry span {
  margin-right: 15px;
}
.supportFormBox {
  padding: 10px 0;
  display: flex;
}
.subAside .asBlocks.support input {
  height: 25px;
  border-radius: 3px;
  padding: 2px 10px;
  border: solid 1px #aaa;
  margin-right: 10px;
}

.closeIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px;
  cursor: pointer;
  display: inline-block;
}
.subAside .asBlocks ul li .closeIcon {
  display: none;
}
.subAside .asBlocks ul li:hover > .closeIcon {
  display: inline-block;
}

.buttonRow {
  text-align: center;
  padding: 5px;
}


/* Account view */

.subAside .asBlocks.reason {
  border-bottom: 0;
}
.subAside .asBlocks.reason ul {
  margin: 0;
  padding: 0;
}
.subAside .asBlocks.reason ul li {
  padding: 2px 0;
}
.subAside .asBlocks.reason ul h3 {
  margin: 0 0 10px 0;
}

.cancellationWrapper {
  border-bottom: solid 1px #ddd;
  padding: 1em;
  display: flex;
  gap: 0 1rem;
}

.AccountOverview {
  display: block;
}
.AccountOverview span.title {
  width: 60%;
  display: inline-block;
}
.AccountOverview span.value {
  display: inline-block;
  color: #3d3d3d;
  font-weight: 400;
}
.AccountOverview span.value.active {
  color: var(--legacy-color-lime-green-500);
}

.AccountOverview span.value.cancelled {
  color: var(--legacy-color-poppy-red-300);
}

ul.cancelQuestionData li {
  position: relative;
  list-style: none;
  padding: 5px 0;
}

.cancelQuestionData .subLabel {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.subAside .asBlocks.buttonRow {
  border-top: solid 1px #ddd;
  text-align: center;
  border-bottom: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn-cancel {
  color: #c91514;
  background: #ffe6e4 !important;
  height: 25px !important;
  margin: 5px 0 !important;
  border: solid 1px #c91514 !important;
}
.btn-cancel:focus {
  outline: none;
  box-shadow: unset !important;
}

.btn-cancel:hover:not(:disabled) {
  background: #f2b0ab !important;
}

.btn-cancel span[data-selector="button--text"] {
  color: #c91514 !important;
}
.grid-title {
  position: relative;
  display: block;
  padding: 5px;
}

.grid-title .right {
  position: absolute;
  right: 0;
  display: inline-block;
  top: 0;
}

.subscription-cancelled-row {
  background-color: #fff8f7 !important;
  /* background-color:  var(--legacy-color-poppy-red-10) !important; */
}

/* --- Should be deleted */
/* .subscriptionWrapper {
  width: 95%;
  margin: 0 0 0 0;
  overflow-y: auto;
  overflow-x: auto;
  font-family: "Nokia Pure Text";
} */

/* .subscriptionContent {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  overflow-x: auto;
}
.subscriptionContent.inside {
  width: 78%;
} */
/* .selection {
  display: flex;
  width: 30%;
  margin-top: 1em;
} */
/* .subAside {
  width: 24.5%;
  float: right;
  position: absolute;
  top: 1em;
  right: 0.5em;
  height: 80vh;
  overflow-y: auto;
  overflow-x: auto; 
} */
