.dFlex5 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
.stepVariantLast div[class^="styles__StepIndicator-"]{
  background-color: #D9D9D9;
}
.captureView{
  display: flex;
  justify-content:space-between
}
.captureViewLabel{
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #757575;
}

.captureViewValue{
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #757575;
}
.pTRBL20{
  padding: 0 0 15px 0;
}
.pdfheader {
  background-color: #005aff;
  color: white;
  padding: 15px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pdfcontainer {
  max-width: 800px;
  margin: 20px auto;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.pdfsection {
  margin-top: 20px;
}
.pdfsectiontitle {
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.pdfrow {

  padding: 5px 0;
}
.pdfrow .pdflabel {
  font-weight: bold;
  color: #333;
}
.pdfhighlight {
  background-color: #e8f0ff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}