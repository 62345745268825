.productSpecificationContainer{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* padding: 1rem; */
    gap: .5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}



.productDescription{
    width: 60%;
    background-color: #fff;
    /* padding: 1rem; */
    border-radius: 10px;
    min-height: 600px;
}

.productSpecification{
    /* width: 40%; */
    background-color: #fff;
    /* padding: 1rem; */
    border-radius: 10px;
    min-height: 300px;
}

.productImageCarousel{
    /* width: 40%; */
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    min-height: 300px;
    margin-bottom: 10px;
}

.productCarouselSpecification{
    width: 40%;
}

.priceChip {
    color: #000; 
    padding: .4rem;
    border-radius: 15px;
    border: 1px solid #80E6FF;
    font-size: .75rem;
    background-color: #E8FBFF;
  }

  .optionLoader {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 50%;
}

.optionLoader.hide {
    display: none;
}

.productSupplierLogo{
    display: block; 
    max-width: 100%;
    height: 18px;
    object-fit: contain
}
.etraTelecomLogo{
    display: block; 
    max-width: 100%;
    height: 30px;
    object-fit: contain
}

