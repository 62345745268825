.marketplaceWrapper {
  width: 100%;
  margin: 0 0 0 0;
  /* overflow-y: auto;
    overflow-x: auto; */
  font-family: "Nokia Pure Text" !important;
}


.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  /* gap: 20px; */
}


.gridSecondRowContainer {
  display: grid;
  grid-template-columns: 25% 39.5% 35%;
  /* Define two columns with 70% and 30% widths */
  gap: 20px;
  /* Optional: Adds spacing between columns */
  height: 100vh;
  /* Optional: Full height layout */
}


.gridOrderContainer {
  display: grid;
  grid-template-columns: 6% 0% 15.1% 12.1% 14.1% 14.1% 15.1%;
  gap: 30px;
  /* Optional: Adds spacing between columns */
  height: auto;
  /* Optional: Full height layout */
}


.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-left: 20px;
}


.image {
  max-width: 40px;
  /* Adjust the size of the image */
  /* Maintain aspect ratio */
}

/* Style for the big number */
.bignumber {
  font-size: 64px;
  /* Big size number */
  font-weight: bold;
  /* Bold number */
  color: #333;
  line-height: 1;
  /* Adjust line height */
}

/* Style for the small text */
.smalltext {
  font-size: 16px;
  /* Smaller font size */
  color: #666;
  /* Lighter color */
  margin-top: 5px;
  /* Add spacing between number and text */
}


.verticalDivider {
  border-left: 1px solid #D7D7D7;
  /* height: 8rem; */

}

.orderDivCountBlock {
  height: 15vh;
  display: flex;
  align-items: center;
}

.orderThisWeek {
  display: flex;
  justify-content: center;
  margin-left: 18px;
  flex-direction: column;
}

.orderThisWeekCalendar {
  justify-content: center;
  display: flex;
  margin-top: -10px;
  margin-right: 20px;
}

.progresssectionblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-left: 20px;
}

.progresscircleinner {
  position: absolute;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  z-index: 1;
}

.progresscircle {
  position: relative;
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #13902F;
  border-radius: 50%;
}

.green {
  border-color: #13902F;
}

.greenbackgroudradius {
  background: radial-gradient(204.17% 204.17% at 50% 50%, #FFF 16.67%, #13902F 100%);
}

.ordernumbercount {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  z-index: 5;
}

.grey {
  border-color: #616770;
}

.greybackgroudradius {
  background: radial-gradient(204.17% 204.17% at 50% 50%, #FFFFFF 16.67%, #616770 100%);
}

.blue {
  border-color: #5A0DD5;
}

.bluebackgroudradius {
  background: radial-gradient(204.17% 204.17% at 50% 50%, #FFFFFF 16.67%, #5A0DD5 100%);
}

.orange {
  border-color: #F47F31;
}

.orangebackgroudradius {
  background: radial-gradient(204.17% 204.17% at 50% 50%, #FFFFFF 16.67%, #F47F31 100%);
}

.red {
  border-color: #C91514;
}

.redbackgroudradius {
  background: radial-gradient(204.17% 204.17% at 50% 50%, #FFFFFF 16.67%, #C91514 100%);
}

/*Dashborad css part*/
.card{
  padding: 20px;
}
.cardheader {
  display: flex;
  align-items: center;
  width: inherit;
}
.cardheaderleftsection {
  flex: 1;
}
.carddetails{
  display: inline-flex;
  align-items: center;
}
.carddetailsleftsect {
  display: inline-flex;
  width: 44px;
  height: 44px;
  margin-right: 12px;
}
.cardcount{
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  color: #333333;
  margin-bottom: 4px;
}
.cardlabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #757575;
}
.cardheaderrightsection {
  text-align: right;
}
.trend {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #13902f;
}
.trenddown {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #C91514;
}
.trendstatus {
  margin-top: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #757575;
}
.trendimg {
  margin-right: 4px;
}
.cardfooter{
  margin-top: 20px;
  width: inherit;
}
.mapheader{
  display: flex;
  align-items: center;
  width: inherit;
  justify-content: space-between;
}
.mapcardcount{
  font-weight: 400;
  /* font-size: 16px; */
  line-height: 125%;
  color: #333333;
  margin-bottom: 4px;
}
.progressbar {
  margin-top: 5px;
  background-color: #edeff4;
  border-radius: 20px; /* (heightOfInnerDiv / 2) + padding */
}
.progressbar > div {  
  height: 8px;
  border-radius: 10px;
}

.catgeoryList{
  display: flex;
  justify-content: space-between;
}

.mapContent{
  padding-top: 20px;
}

.mapContent div[class^="styles__Container-"] {
   height: 35vh !important
}
.barchart div[class^="styles__Container-"] {
  /* height: 50vh !important; */
}

.gridMerchantOderOfferContainer {
  display: grid;
  grid-template-columns: 25% 39.5% 35%;
  gap: 20px;
}
.borderBottomLine {
  border-bottom: 1px solid #0000001F;
  margin: 1rem 0;
  padding: 0 !important;
}
.productListDataGrod{
  height: 600px;
  overflow: auto;
}

.productListDataGrod   div[class="ag-body-vertical-scroll-viewport"] {
  overflow-y: hidden;
}


.productListDataGrod   div[class="ag-body-horizontal-scroll-viewport"] {
  overflow-x: hidden;
}


.buttongroup {
  display: flex;
  gap: 10px;
}

/* Common button styling */
.buttonMap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

/* Circle styling for all buttons */
.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

/* Specific colors for each button */
.low .circle {
  background-color: #A3C4F3; /* Light blue */
}

.medium .circle {
  background-color: #0057FF; /* Blue */
}

.high .circle {
  background-color: #1C3F94; /* Dark blue */
}

/* Hover effect */
.buttonMap:hover {
  opacity: 0.9;
  transform: scale(1.05);
  transition: all 0.2s ease;
}
