.marketplaceWrapper {
    width: 100%;
    margin: 0 0 0 0;
    /* overflow-y: auto;
    overflow-x: auto; */
    font-family: "Nokia Pure Text" !important;
  }
  .marketplaceContent {
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    overflow-x: auto;
  }

  .marketplaceProductsCatalogContainer{
    /* padding: 1rem; */
  }

  