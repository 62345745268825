.sub-filter {
  border-bottom: solid 1px #ddd;
  display: flex;
  margin: 0 0;
  padding: 1em;
  padding: 1em;
}
.selection {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 5%;
  position: relative;
}

.selection select {
  border: solid 1px #999494;
  height: 2rem;
  border-radius: 0.125rem;
}

.optionLoader {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  right: 50%;
}
.optionLoader.hide {
  display: none;
}
